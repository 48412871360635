import React from "react";
import "./Spinner.css";

const Spinner = () => {
	return (
		<div className="loadingSpinnerContainer">
			<div className="loadingSpinner"></div>
		</div>
	);
};

export default Spinner;
