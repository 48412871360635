const firebaseConfig = {
	apiKey: "AIzaSyB2_rE34xTiOYdTrpih29cPuTzfFnl5wIs",
	authDomain: "buzz-316eb.firebaseapp.com",
	projectId: "buzz-316eb",
	storageBucket: "buzz-316eb.appspot.com",
	messagingSenderId: "709764606714",
	appId: "1:709764606714:web:f32ee431dc11e6197bb743",
};

export default firebaseConfig;
